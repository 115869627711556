import { assert } from '@ember/debug'
import Service, { inject as service } from '@ember/service'
import type ActivityModel from 'fast-phonics-client/models/activity'
import type ActivityService from 'fast-phonics-client/services/activity'
import type SessionService from 'fast-phonics-client/services/session'
import {
  hasFinishedProgram,
  canPlayPeakLesson,
  canPlayStoneActivity,
} from 'fast-phonics-client/utils/progress'
import type { ProgressData } from 'fast-phonics-client/models/progress'
import type ProgressModel from 'fast-phonics-client/models/progress'

export default class ProgressService extends Service {
  @service declare activity: ActivityService

  @service declare session: SessionService

  get lastActivity(): ActivityModel {
    const { lastActivity } = this.activity
    return lastActivity
  }

  get lessonsProgress(): ProgressModel {
    const lessonsProgress = this.session.student.progress.find(
      (progress) => progress.precinct === 'lessons',
    )
    assert('lessonsProgress data is set', lessonsProgress)
    return lessonsProgress
  }

  get lessonsProgressData(): ProgressData {
    assert('progress data is set', this.lessonsProgress.progressData)
    return this.lessonsProgress.progressData
  }

  get lastAccessiblePeak(): number {
    const progressPeak = this.lessonsProgressData.peak
    const { lastPeak } = this.activity

    return Math.min(progressPeak, lastPeak)
  }

  get hasFinishedProgram(): boolean {
    return hasFinishedProgram(this.lessonsProgressData, this.lastActivity)
  }

  get hasViewedIntroVideo(): boolean {
    assert(
      'viewedIntroVideo is defined',
      typeof this.session.student.viewedIntroVideo === 'boolean',
    )
    return this.session.student.viewedIntroVideo
  }

  get showPlacementTest(): boolean {
    return this.lessonsProgressData.showPlacementTest
  }

  canPlayPeak(peakId: number): boolean {
    assert(
      'lastAccessiblePeak is of type number',
      typeof this.lastAccessiblePeak === 'number',
    )

    return (
      peakId <= this.lastAccessiblePeak &&
      canPlayPeakLesson(this.lessonsProgressData, peakId).ok
    )
  }

  canPlayStone(peakId: number, stoneId: string): boolean {
    const requestedPosition = this.activity.findPositionInPeakFor(
      peakId,
      stoneId,
    )

    if (requestedPosition.ok) {
      const canPlayStone = canPlayStoneActivity(
        this.lessonsProgressData,
        peakId,
        requestedPosition.id,
      )
      return canPlayStone.ok
    }
    return false
  }
}

declare module '@ember/service' {
  interface Registry {
    progress: ProgressService
  }
}
