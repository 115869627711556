import Model, { attr, belongsTo } from '@ember-data/model'
import type { Attr } from 'fast-phonics-client/utils/models'
import type StudentModel from 'fast-phonics-client/models/student'
import type RewardTotalModel from 'fast-phonics-client/models/reward-total'

export default class PurchaseModel extends Model {
  @attr('string') uuid: Attr<string>

  @attr('number') price: Attr<number>

  @attr('string') category: Attr<string>

  @attr('string') item: Attr<string>

  @belongsTo('student', { async: false, inverse: 'purchases' })
  declare student: StudentModel

  @belongsTo('reward-total', { async: false, inverse: null })
  declare rewardTotals: RewardTotalModel
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    purchase: PurchaseModel
  }
}
