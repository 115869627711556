import Route from '@ember/routing/route'

interface ParamsForRoute {
  peak_id: string
  stone_id: string
}

export default class StoneRoute extends Route<void, ParamsForRoute> {
  override model(_params: ParamsForRoute): void {
    // This empty model hook prevents Ember's default behaviour of attempting
    // to load a Peak model from the ember-data store that matches the current
    // peak ID in the URL.
  }
}
