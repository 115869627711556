import Model, { attr } from '@ember-data/model'
import type { Attr } from 'fast-phonics-client/utils/models'

export default class ActivityModel extends Model {
  @attr('number') peak: Attr<number>

  @attr('number') positionInPeak: Attr<number>

  @attr('string') manifestBundle: Attr<string>

  @attr('string') manifestKey: Attr<string>

  @attr('string') activityType: Attr<string>
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    activity: ActivityModel
  }
}
