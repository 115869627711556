import EmberRouter from '@ember/routing/router'
import config from 'fast-phonics-client/config/environment'

export default class Router extends EmberRouter {
  location = config.locationType

  rootURL = config.rootURL
}

Router.map(function () {
  this.route('peaks', function () {
    this.route('peak', { path: '/:peak_id' }, function () {
      // Peak Activities (All Stones)
      this.route('stones')
    })
  })

  // Interactive (Single Stone)
  this.route('stone', { path: '/peaks/:peak_id/:stone_id' }, function () {
    // EOM Quiz
  })

  this.route('end-of-program')

  this.route('my-progress', function () {
    // The index is the "Progress" tab
    this.route('sounds')
    this.route('words')
    this.route('books')
    this.route('certificates')
  })

  this.route('sound', { path: '/my-progress/sounds/:sound' })
  this.route('book', { path: '/my-progress/books/:book_id' })

  // intro video when user does not have progress
  this.route('intro-video', function () {})

  this.route('yeti-shop', function () {})

  this.route('login')
  this.route('logout')

  this.route('error', function () {
    this.route('unauthorized')
  })

  this.route('not-found', { path: '/*page' })
  this.route('placement-test')
})
