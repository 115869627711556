import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import { assert } from '@ember/debug'
import type { PeaksContentSpec } from '@blakeelearning/content-specs-fast-phonics'
import { peaksContentSpec as contentSpec } from '@blakeelearning/content-specs-fast-phonics'
import type Activity from 'fast-phonics-client/services/activity'
import type RouterService from '@ember/routing/router-service'
import type Transition from '@ember/routing/-private/transition'
import type RouteInfo from '@ember/routing/-private/route-info'
import type SessionService from 'fast-phonics-client/services/session'
import type ProgressService from 'fast-phonics-client/services/progress'
import toJSON from 'fast-phonics-client/utils/to-json'
import type RewardTotalModel from 'fast-phonics-client/models/reward-total'

export interface ModelForRoute {
  manifests: string[][]
  variables: Record<string, unknown>
  contentSpec: PeaksContentSpec
}

enum PeakView {
  'stones' = 'stones',
  'peak' = 'peak',
}

export default class PeaksRoute extends Route<ModelForRoute> {
  @service declare activity: Activity

  @service declare session: SessionService

  @service declare progress: ProgressService

  @service declare router: RouterService

  get userRewards(): RewardTotalModel {
    assert('rewardTotals are set', this.session.student.rewardTotals)
    return this.session.student.rewardTotals
  }

  override beforeModel(transition: Transition): void {
    if (this.progress.showPlacementTest) {
      void this.router.transitionTo('placement-test')
      return
    }

    const requestedPeakId = Number(
      this._getRequestedPeakIdFromRoute(transition.to),
    )

    // If no peakId is passed, or if the provided peakId is inaccessible,
    // redirect the student to their current peak.
    if (!requestedPeakId || !this.progress.canPlayPeak(requestedPeakId)) {
      void this.router.replaceWith(
        'peaks.peak',
        this.progress.lastAccessiblePeak,
      )
    }

    // Addresses a case where a user clicks browser back button before they click 'Next' in End of Peak Quiz
    // If this happens the chest unlock doesn't occur, which prevents progression from incrementing and
    // prevents the user from accessing stones
    if (
      this.progress.lessonsProgressData.positionInPeak === 1 &&
      !this.progress.hasFinishedProgram
    ) {
      void this.router.replaceWith(
        'peaks.peak',
        this.progress.lessonsProgressData.peak,
      )
    }
  }

  override model(_params: unknown, transition: Transition) {
    const { selectedYeti } = this.session.student
    const userProgress = this.progress.lessonsProgressData
    const { lastPeak } = this.activity

    const { to: requestedRouteInfo } = transition
    const requestedPeakId =
      this._getRequestedPeakIdFromRoute(requestedRouteInfo)
    const requestedPeakView =
      this._getRequestedPeakViewFromRoute(requestedRouteInfo)

    const manifests = [['index', 'activities/peaks']]
    const variables = {
      userRewards: toJSON(this.userRewards),
      requestedPeakView,
      requestedPeakId,
      userProgress,
      lastPeak,
      selectedYeti,
    }

    return { manifests, variables, contentSpec }
  }

  /**
   * Traverses the route metadata in order to pull the requested peak_id from the params.
   *
   * Allows us to render an interactive in a parent route, while accessing
   * route params from a child route.
   * This is required to prevent the interactive from re-rendering when child route params change
   */
  _getRequestedPeakIdFromRoute(
    routeInfo: RouteInfo | null,
  ): string | undefined {
    const peakRouteInfo = routeInfo?.find(
      (route) => route.name === 'peaks.peak',
    )
    return peakRouteInfo?.params['peak_id']
  }

  /**
   * Traverses the route metadata to find which view the user should receive.
   *
   * Allows us to render an interactive in a parent route, while accessing
   * route params from a child route.
   * This is required to prevent the interactive from re-rendering when child route params change
   */
  _getRequestedPeakViewFromRoute(routeInfo: RouteInfo | null): PeakView {
    const isRequestingStonesView = routeInfo?.find(
      (route) => route.name === 'peaks.peak.stones',
    )
    return isRequestingStonesView ? PeakView.stones : PeakView.peak
  }
}
