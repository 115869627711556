import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type Store from '@ember-data/store'
import type RouterService from '@ember/routing/router-service'
import type DebugModeService from 'fast-phonics-client/services/debug-mode'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'
import type SessionService from 'fast-phonics-client/services/session'
import type ProgressService from 'fast-phonics-client/services/progress'
import type { StoneContentActions } from '@blakeelearning/content-specs-fast-phonics'
import type StoneIndexRoute from 'fast-phonics-client/routes/stone/index'
import type { ModelFor } from 'fast-phonics-client/utils/route-model'
import toJSON from 'fast-phonics-client/utils/to-json'
import type { ErrorLike } from 'fast-phonics-client/utils/errors'
import { contentError } from 'fast-phonics-client/utils/errors'
import type PeakCertificateService from 'fast-phonics-client/services/peak-certificate'
import { assert } from '@ember/debug'

export interface QuizData {
  correctAnswers: string[]
  incorrectAnswers?: string[][] | undefined
}

interface SavePeakQuizResultArgs extends QuizData {
  uuid: string
}

interface ActivityResultParams {
  uuid: string
  correctCount: number
  totalCount: number
}

interface ActivityResult {
  activityResult: Record<string, unknown>
}

export default class StoneIndexController
  extends Controller
  implements StoneContentActions
{
  declare model: ModelFor<StoneIndexRoute>

  @service declare debugMode: DebugModeService

  @service declare errorHandler: ErrorHandlerService

  @service declare loadingUi: LoadingUiService

  @service declare session: SessionService

  @service declare progress: ProgressService

  @service declare peakCertificate: PeakCertificateService

  @service declare router: RouterService

  @service declare store: Store

  get activityId(): string {
    return this.model.activityId
  }

  get activityType(): string {
    return this.model.activityType
  }

  get hasFinishedProgram(): boolean {
    return this.progress.hasFinishedProgram
  }

  get lastAccessiblePeak(): number {
    return this.progress.lastAccessiblePeak
  }

  get peakId(): number {
    return this.model.peakId
  }

  get stoneId(): string {
    return this.model.stoneId
  }

  get studentFirstName(): string {
    assert('firstName is set', this.session.student.firstName)
    return this.session.student.firstName
  }

  @action
  readyForUserInput() {
    this.loadingUi.finish()
  }

  @action
  unhandledError({ error }: { error: ErrorLike }) {
    this.errorHandler.handleContentUnhandledError(error)
  }

  @action
  print(data: { certificateUrl: string }) {
    return this.peakCertificate.print(data)
  }

  @action
  async completeVideo(): Promise<void> {
    if (this.model.completeVideoCalled) return
    this.model.completeVideoCalled = true

    const activityResult = this.store.createRecord('activity-result', {
      activityId: this.activityId,
      student: this.session.student,
    })

    try {
      await activityResult.save()

      this.loadingUi.start(() => {
        void this.router.transitionTo('peaks.peak.stones', this.peakId)
      })
    } catch (error) {
      activityResult.unloadRecord()
      this.errorHandler.handleError(
        `Error completing video: ${(error as Error).message}`,
        error,
      )
    }
  }

  @action
  async saveBookQuizResult({
    quizData: { correctAnswers, incorrectAnswers },
    readRepeat,
    bookName,
  }: {
    quizData: QuizData
    readRepeat: boolean
    bookName: string
  }): Promise<void> {
    const bookQuizResult = this.store.createRecord('book-quiz-result', {
      peak: this.peakId,
      activityId: this.activityId,
      bookName,
      correctAnswers,
      incorrectAnswers,
      replayWithAudio: readRepeat,
      student: this.session.student,
    })

    try {
      await bookQuizResult.save()
    } catch (error) {
      bookQuizResult.unloadRecord()
      this.errorHandler.handleError(
        `Error completing book quiz: ${(error as Error).message}`,
        error,
      )
    }
  }

  @action
  async completeBookQuiz(
    quizData: QuizData,
    readRepeat: boolean,
  ): Promise<void> {
    if (this.model.completeBookQuizCalled) return
    this.model.completeBookQuizCalled = true

    await this.saveBookQuizResult({ quizData, readRepeat, bookName: 'unknown' })
    this.next()
  }

  @action
  goBack(): void {
    this.loadingUi.start(() => {
      window.history.back()
    })
  }

  @action
  next(): void {
    const isQuiz = this.activityType === 'quiz'

    if (isQuiz) {
      if (this.hasFinishedProgram) {
        this.loadingUi.start(() => {
          void this.router.transitionTo('end-of-program')
        })
      } else {
        this.loadingUi.start(() => {
          void this.router.transitionTo('peaks.peak', this.lastAccessiblePeak)
        })
      }
    } else {
      this.loadingUi.start(() => {
        void this.router.transitionTo('peaks.peak.stones', this.peakId)
      })
    }
  }

  @action
  async savePeakQuizResult({
    correctAnswers,
    incorrectAnswers,
    uuid: attemptUuid,
  }: SavePeakQuizResultArgs) {
    const peakQuizResult = this.store.createRecord('peak-quiz-result', {
      peak: this.peakId,
      activityId: this.activityId,
      correctAnswers,
      incorrectAnswers,
      student: this.session.student,
      studentName: this.studentFirstName,
      attemptUuid,
    })

    try {
      await peakQuizResult.save()
    } catch (error) {
      peakQuizResult.unloadRecord()
      throw contentError('SaveFailed', error)
    }

    const { coinsEarned, passed } = peakQuizResult
    let { certificateUrl } = peakQuizResult
    if (passed) {
      certificateUrl ??= this.peakCertificate.generateCertificateUrl({
        peakId: this.peakId,
        colour: this.peakCertificate.calculateColour({
          correctAnswers,
          incorrectAnswers,
        }),
        name: this.studentFirstName,
        timestamp: Date.now(),
      })
    }

    return { peakQuizResult: { coinsEarned, passed, certificateUrl } }
  }

  @action
  async saveActivityResult({
    uuid,
    correctCount,
    totalCount,
  }: ActivityResultParams): Promise<ActivityResult> {
    // Add this to make sure content passed a UUID even though we can't use it as-yet
    if (!uuid) throw new Error('uuid is required')

    const isExistingUUID = this.store
      .peekAll('activity-result')
      .slice()
      .some((item) => item.uuid === uuid)
    if (isExistingUUID) throw new Error('Attempted to reuse UUID')

    const activityResult = this.store.createRecord('activity-result', {
      uuid,
      activityId: this.activityId,
      student: this.session.student,
      correctCount,
      totalCount,
    })

    try {
      await activityResult.save()
    } catch (error) {
      activityResult.unloadRecord()
      throw error
    }

    return { activityResult: toJSON(activityResult) }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'stone/index': StoneIndexController
  }
}
