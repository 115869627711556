import JSONSerializer from '@ember-data/serializer/json'

/**
 * This serializer preserves camelCase keys,
 * which is expected by Jester activities.
 */
export default class JesterJSONSerializer extends JSONSerializer {}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    jester: JesterJSONSerializer
  }
}
