import Route from '@ember/routing/route'

export default class PeakRoute extends Route {
  override model(): void {
    /**
     * This empty model hook prevents Ember's default behaviour of attempting
     * to load a Peak model from the ember-data store that matches the current
     * peak ID in the URL.
     */
  }
}
