import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type { VideoContentSpec } from '@blakeelearning/content-specs-fast-phonics'
import { videoContentSpec as contentSpec } from '@blakeelearning/content-specs-fast-phonics'
import type DebugModeService from 'fast-phonics-client/services/debug-mode'

interface ModelForRoute {
  contentSpec: VideoContentSpec
  manifests: string[][]
}

export default class IntroVideoRoute extends Route<ModelForRoute> {
  @service declare debugMode: DebugModeService

  override model() {
    const manifests = [['index', 'activities/intro-video']]
    return { manifests, contentSpec }
  }
}
