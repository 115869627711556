import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import type ProgressService from 'fast-phonics-client/services/progress'

export default class ErrorIndexController extends Controller {
  @service declare progress: ProgressService

  get placementTestRequired(): boolean {
    return this.progress.showPlacementTest
  }
}

declare module '@ember/controller' {
  interface Registry {
    'error/index': ErrorIndexController
  }
}
