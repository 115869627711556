import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import type ProgressService from 'fast-phonics-client/services/progress'

export default class IndexRoute extends Route {
  @service declare progress: ProgressService

  @service declare router: RouterService

  override redirect(): void {
    if (!this.progress.hasViewedIntroVideo) {
      void this.router.replaceWith('intro-video')
    } else if (this.progress.showPlacementTest) {
      void this.router.replaceWith('placement-test')
    } else if (this.progress.hasFinishedProgram) {
      void this.router.replaceWith('end-of-program')
    } else {
      void this.router.replaceWith('peaks')
    }
  }
}
