const isPeaksRoute = (name?: string) => /^peaks\b/.test(name ?? '')
const isMyProgressRoute = (name?: string) => /^my-progress\b/.test(name ?? '')

/**
 * Returns true iff the route is an error page
 */
export const isErrorRoute = (name: string) => /^(not-found|error)\b/.test(name)

/**
 * Returns true iff a given route names will both display the peaks interactive
 */
const isInsidePeaks = (to: string, from?: string) => [from, to].every(isPeaksRoute)

/**
 * Returns true iff the given route names will both display the progress-page interactive
 */
const isInsideMyProgress = (to: string, from?: string) => [from, to].every(isMyProgressRoute)

/**
 * Returns true iff the given route names both display the same interactive
 */
export const isInsideInteractive = (to: string, from?: string) =>
  [isInsidePeaks, isInsideMyProgress].some((check) => check(to, from))
