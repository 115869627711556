import type { AsyncBelongsTo } from '@ember-data/model'
import Model, { attr, belongsTo } from '@ember-data/model'
import type StudentModel from 'fast-phonics-client/models/student'

export default class PlacementTestResultModel extends Model {
  @attr('number') questionNumber?: number

  @attr('boolean') skippedTest?: boolean

  @attr('string') testType?: string

  @attr('string') status?: string

  @belongsTo('student', { async: true, inverse: null })
  declare student: AsyncBelongsTo<StudentModel>
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'placement-test-result': PlacementTestResultModel
  }
}
