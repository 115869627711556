import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import { assert } from '@ember/debug'
import type { YetiShopContentSpec } from '@blakeelearning/content-specs-fast-phonics'
import { yetiShopContentSpec as contentSpec } from '@blakeelearning/content-specs-fast-phonics'
import type SessionService from 'fast-phonics-client/services/session'
import type RouterService from '@ember/routing/router-service'
import type ActivityService from 'fast-phonics-client/services/activity'
import type ProgressService from 'fast-phonics-client/services/progress'
import type Store from '@ember-data/store'
import type { ProgressData } from 'fast-phonics-client/models/progress'
import type EmberArray from '@ember/array'
import type YetiModel from 'fast-phonics-client/models/yeti'
import type RewardTotalModel from 'fast-phonics-client/models/reward-total'
import toJSON from 'fast-phonics-client/utils/to-json'

export interface PurchasedYetis {
  category: string
  item: string
}

interface ModelForRoute {
  manifests: string[][]
  variables: Record<string, unknown>
  contentSpec: YetiShopContentSpec
  yetis: EmberArray<YetiModel>
}

export default class YetiShopRoute extends Route<ModelForRoute> {
  @service declare session: SessionService

  @service declare router: RouterService

  @service declare store: Store

  @service declare activity: ActivityService

  @service declare progress: ProgressService

  get rewardTotals(): RewardTotalModel {
    assert('rewardTotals is set', this.session.student.rewardTotals)
    return this.session.student.rewardTotals
  }

  get coins(): number {
    assert('coins is set', this.rewardTotals.coins)
    return this.rewardTotals.coins
  }

  get lessonsProgressData(): ProgressData {
    return this.progress.lessonsProgressData
  }

  get hasFinishedProgram(): boolean {
    return this.progress.hasFinishedProgram
  }

  get selectedYeti(): string {
    assert('selected yeti is defined', this.session.student.selectedYeti)
    return this.session.student.selectedYeti
  }

  get purchasedYetis(): PurchasedYetis[] {
    const { purchases } = this.session.student
    return purchases.map(({ category, item }) => {
      assert('purchase category exists', category)
      assert('purchase item exists', item)

      return { category, item }
    })
  }

  override beforeModel(): void {
    if (this.progress.showPlacementTest) {
      void this.router.transitionTo('placement-test')
    }
  }

  override async model(): Promise<ModelForRoute> {
    const yetis = await this.store.findAll('yeti')

    const { purchasedYetis, coins, selectedYeti } = this

    const yetisJSON = yetis.map((yeti) => toJSON(yeti, { includeId: true }))

    const yetiShopVariables = {
      purchases: purchasedYetis,
      coins,
      selectedYeti,
      yetis: yetisJSON,
    }

    const manifests = [['index', 'activities/yeti-shop']]
    const variables = yetiShopVariables
    return { manifests, variables, contentSpec, yetis }
  }
}
