import Service, { inject as service } from '@ember/service'
import { UnauthorizedError } from '@ember-data/adapter/error'
import type { ErrorLike } from 'fast-phonics-client/utils/errors'
import {
  errorIsActionable,
  isErrorLike,
} from 'fast-phonics-client/utils/errors'
import type RouterService from '@ember/routing/router-service'
import type { Log } from '@blakeelearning/log'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'

export type UnhandledError = Partial<ErrorLike>

export default class ErrorHandlerService extends Service {
  @service declare loadingUi: LoadingUiService

  @service declare router: RouterService

  @service declare log: Log

  /**
   * Transitions the browser to an error page appropriate to the error provided.
   */
  transitionToErrorRoute(error?: unknown): void {
    const route =
      error instanceof UnauthorizedError ? 'error.unauthorized' : 'error'
    this.loadingUi.start(() => {
      void this.router.transitionTo(route)
    })
  }

  /**
   * This is a handler for any time Content code calls
   * `externalController.unhandledError`, indicating that it has encountered an
   * error it cannot handle.  It:
   *
   * 1. logs the error to rollbar
   * 2. transitions to an error route
   *
   * Because these calls come from Content, we can't depend on any instanceof
   * checks.
   */
  handleContentUnhandledError(error: unknown): void {
    let name = 'Error'
    let message = 'Unknown Error'

    if (typeof error === 'object' && error !== null) {
      if ('name' in error && typeof error.name === 'string') {
        name = error.name
      }

      if ('message' in error && typeof error.message === 'string') {
        message = error.message
      }
    }

    this.handleError(`Unhandled ${name} returned from content: ${message}`)
  }

  /**
   * This is a general-purpose function for handling errors.  It accepts an
   * error message and an optional error object.  With these it:
   *
   * 1. logs the error to rollbar, unless the error is considered un-actionable
   * 2. transitions to an error route
   *
   * @see {@link #errorIsActionable}
   */
  handleError(message: string, error?: unknown): void {
    this.logErrorIfActionable(message, error)
    this.transitionToErrorRoute(error)
  }

  /**
   * This is a general-purpose handler for any time that controller code
   * encounters an unrecoverable error.  This is any error that occurs in
   * mathseeds-prime-client in the course of actioning a request from Content that
   * we know that the Content code has no means of recovering from. It:
   *
   * 1. logs the error to rollbar
   * 2. transitions to an error route
   */
  handleUnrecoverableError(error: unknown): void {
    let message: string
    if (isErrorLike(error)) {
      message = error.message
    } else if (typeof error === 'string') {
      message = error
    } else {
      message = 'Unknown error'
    }
    this.handleError(`Unrecoverable error: ${message}`, error)
  }

  /**
   * This will log errors that are considered actionable.
   *
   * @see {@link #errorIsActionable}
   */
  logErrorIfActionable(message: string, error?: unknown): void {
    if (!error || !(error instanceof Error)) {
      this.log.error(message)
    } else if (errorIsActionable(error)) {
      this.log.error(message, error)
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'error-handler': ErrorHandlerService
  }
}
