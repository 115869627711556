import Service, { inject as service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import type { FeatureService } from '@blakeelearning/features'

const SESSION_STORAGE_KEY = 'fastPhonicsClient.debugMode.enabled'

/**
 * Tracks the enabled / disabled state of the app's Debug Mode
 *
 * This service uses an underlying SessionStorage key to persist the
 * state of debug mode, combining that data with app config to decide
 * whether debug mode should be enabled.
 *
 * This computation is then set in the tracked `enabled` property, so
 * consumers can use it in situations where autotracking is required.
 */
export default class DebugModeService extends Service {
  @service declare features: FeatureService

  @tracked enabled = this._shouldEnable()

  get debugAllowed(): boolean {
    const client = this.features.getClient()
    return client.getBooleanValue('debugAllowedFF', false)
  }

  enable(): void {
    if (this.debugAllowed) {
      this._setSessionStorage(true)
      const shouldEnable = this._shouldEnable()
      this.enabled = shouldEnable
    }
  }

  disable(): void {
    if (this.debugAllowed) {
      this._setSessionStorage(false)
      this.enabled = false
    }
  }

  _shouldEnable(): boolean {
    try {
      return this.debugAllowed && this._getSessionStorage() === 'true'
    } catch (error) {
      console.error(error)
      return false
    }
  }

  _getSessionStorage(): string | null {
    const sessionStorageItem = sessionStorage.getItem(SESSION_STORAGE_KEY)
    return sessionStorageItem
  }

  _setSessionStorage(value: boolean): void {
    try {
      sessionStorage.setItem(SESSION_STORAGE_KEY, value ? 'true' : 'false')
    } catch (err) {
      console.warn(`Unable to set sessionStorage item "${SESSION_STORAGE_KEY}"`)
      console.error(err)
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'debug-mode': DebugModeService
  }
}
