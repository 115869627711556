import ApplicationAdapter from 'fast-phonics-client/adapters/application'

export default class StudentResultAdapter extends ApplicationAdapter {
  override urlForFindRecord(id: string): string {
    const baseUrl = this.buildURL('student', id)
    return `${baseUrl}/result`
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'student-result': StudentResultAdapter
  }
}
