import ApplicationAdapter from 'fast-phonics-client/adapters/application'

export default class Adapter extends ApplicationAdapter {
  urlForCreateRecord(modelName, snapshot) {
    const studentId = snapshot.belongsTo('student').id

    return `${this.buildURL('student', studentId)}/${this.pathForType(
      modelName,
    )}`
  }
}
