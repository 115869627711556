import Route from '@ember/routing/route'
import { service } from '@ember/service'
import config from 'fast-phonics-client/config/environment'
import type SessionService from 'fast-phonics-client/services/session'

const {
  APP: { loginWarning },
} = config

interface ModelForRoute {
  warning: string | null
}

export default class LoginRoute extends Route<ModelForRoute> {
  @service
  declare session: SessionService

  /**
   * Implementation of the model hook.  The `redirectUrl` is used by `RedirectRoute`.
   */
  override model() {
    return {
      warning: loginWarning,
    }
  }

  /**
   * Implementation of the route redirect hook.  This will be called by Ember after the model has been resolved.
   */
  override redirect(): void {
    this.session.signIn()
  }
}
