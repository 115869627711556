import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { assert } from '@ember/debug'
import type Store from '@ember-data/store'
import type RouterService from '@ember/routing/router-service'
import type ActivityService from 'fast-phonics-client/services/activity'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'
import type SessionService from 'fast-phonics-client/services/session'
import type { PeaksContentActions } from '@blakeelearning/content-specs-fast-phonics'

export default class PeaksController extends Controller {
  @service declare activity: ActivityService

  @service declare errorHandler: ErrorHandlerService

  @service declare loadingUi: LoadingUiService

  @service declare session: SessionService

  @service declare router: RouterService

  @service declare store: Store

  get contentActions(): PeaksContentActions {
    return {
      readyForUserInput: () => {
        this.loadingUi.finish()
      },
      unhandledError: ({ error }: { error: unknown }) => {
        this.errorHandler.handleContentUnhandledError(error)
      },
      logOut: () => {
        this.logOut()
      },
      startIntroVideo: () => {
        this.startIntroVideo()
      },
      updatePeakView: (peakId: number, peakView: string) => {
        this.updatePeakView(peakId, peakView)
      },
      goToPeak: (peakId: number) => {
        this.transitionToPeak(peakId)
      },
      goToStonesForPeak: (peakId: number) => {
        this.transitionToStones(peakId)
      },
      goToActivity: (peakId: number, positionInPeak: number) => {
        this.transitionToStoneActivity(peakId, positionInPeak)
      },
      goToSounds: () => {
        this.transitionToSounds()
      },
      goToWords: () => {
        this.transitionToWords()
      },
      goToBooks: () => {
        this.transitionToBooks()
      },
      goToProgress: () => {
        this.transitionToProgress()
      },
      goToYetiShop: () => {
        this.goToYetiShop()
      },
      saveProgress: (peakId: number, positionInPeak: number) =>
        this.saveProgress(peakId, positionInPeak),
    }
  }

  @action logOut(): void {
    void this.router.transitionTo('logout')
  }

  @action startIntroVideo(): void {
    this.loadingUi.start(() => {
      void this.router.transitionTo('intro-video')
    })
  }

  @action updatePeakView(peakId: number, peakView: string): void {
    if (peakView === 'peak') {
      void this.router.transitionTo('peaks.peak', peakId)
    } else if (peakView === 'stones') {
      void this.router.transitionTo('peaks.peak.stones', peakId)
    }
  }

  @action transitionToPeak(peakId: number): void {
    void this.router.transitionTo('peaks.peak', peakId)
  }

  @action transitionToStones(peakId: number): void {
    void this.router.transitionTo('peaks.peak.stones', peakId)
  }

  @action transitionToStoneActivity(
    peakId: number,
    positionInPeak: number,
  ): void {
    const stoneId = this.activity.findStoneIdFor(peakId, positionInPeak)
    assert('stoneId result returns ok: true', stoneId.ok)

    this.loadingUi.start(() => {
      void this.router.transitionTo('stone', peakId, stoneId.id)
    })
  }

  @action transitionToSounds(): void {
    this.loadingUi.start(() => {
      void this.router.transitionTo('my-progress.sounds')
    })
  }

  @action transitionToWords(): void {
    this.loadingUi.start(() => {
      void this.router.transitionTo('my-progress.words')
    })
  }

  @action transitionToBooks(): void {
    this.loadingUi.start(() => {
      void this.router.transitionTo('my-progress.books')
    })
  }

  @action transitionToProgress(): void {
    this.loadingUi.start(() => {
      void this.router.transitionTo('my-progress.index')
    })
  }

  @action goToYetiShop(): void {
    this.loadingUi.start(() => {
      void this.router.transitionTo('yeti-shop')
    })
  }

  @action async saveProgress(
    peakId: number,
    positionInPeak: number,
  ): Promise<void> {
    const activityService = this.activity
    const chest = activityService.findActivityIdByPosition(
      peakId,
      positionInPeak,
    )

    if (chest.ok) {
      const createRecordForChest = this.store.createRecord('activity-result', {
        activityId: chest.id,
        student: this.session.student,
      })

      try {
        await createRecordForChest.save()
      } catch (error) {
        createRecordForChest.unloadRecord()
        this.errorHandler.handleError(
          `Error saving chest unlock: ${(error as Error).message}`,
          error,
        )
      }
    } else {
      throw new Error(chest.errorMessage)
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    peaks: PeaksController
  }
}
