import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import { assert } from '@ember/debug'
import type { BookContentSpec } from '@blakeelearning/content-specs-fast-phonics'
import { bookContentSpec as contentSpec } from '@blakeelearning/content-specs-fast-phonics'
import type SessionService from 'fast-phonics-client/services/session'
import type Store from '@ember-data/store'
import type StudentModel from 'fast-phonics-client/models/student'
import type Transition from '@ember/routing/-private/transition'
import type RouterService from '@ember/routing/router-service'

type ModelForRoute = Model | undefined
interface Model {
  contentSpec: BookContentSpec
  manifests: string[][]
  variables: Record<string, unknown>
}

interface ParamsForRoute {
  book_id: string
}

export default class BookRoute extends Route<ModelForRoute, ParamsForRoute> {
  @service declare session: SessionService

  @service declare store: Store

  @service declare router: RouterService

  get student(): StudentModel {
    return this.session.student
  }

  override async model(
    { book_id: bookId }: ParamsForRoute,
    transition: Transition,
  ) {
    const studentHasReadBook = await this._studentHasReadBook(bookId)

    if (studentHasReadBook) {
      // Something has gone wrong - the student has read this book, but we can't
      // find it in the activities index.
      const activity = this.store.peekRecord('activity', bookId)
      if (!activity) throw new Error(`No such book ID: ${bookId}`)

      const { manifestBundle: manifestBundleName, manifestKey } = activity

      assert('manifestBundleName exists', manifestBundleName)
      assert('manifestKey exists', manifestKey)

      const manifests = [[manifestKey, manifestBundleName]]
      const variables = { displayQuiz: false }

      return { manifests, variables, contentSpec }
    }

    // This student hasn't yet read the book they are trying to access - abort the transition or redirect.
    if (transition.from) {
      void transition.abort()
    } else {
      void this.router.replaceWith('my-progress.books')
    }

    return undefined
  }

  /**
   * Resolves to true iff the student has access to the book in question
   */
  async _studentHasReadBook(bookId: string): Promise<boolean> {
    const { books } = await this.student.studentResult
    assert('books exist', books)
    return books.includes(bookId)
  }
}
