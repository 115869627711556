import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import { assert } from '@ember/debug'
import type { VideoContentSpec } from '@blakeelearning/content-specs-fast-phonics'
import { videoContentSpec as contentSpec } from '@blakeelearning/content-specs-fast-phonics'

import type SessionService from 'fast-phonics-client/services/session'
import type StudentModel from 'fast-phonics-client/models/student'
import type Transition from '@ember/routing/-private/transition'

type ModelForRoute = Model | undefined

interface Model {
  manifests: string[][]
  contentSpec: VideoContentSpec
}

interface ParamsForRoute {
  sound: string
}

export default class SoundRoute extends Route<ModelForRoute, ParamsForRoute> {
  @service declare session: SessionService

  @service declare router: RouterService

  get student(): StudentModel {
    return this.session.student
  }

  override async model({ sound }: ParamsForRoute, transition: Transition) {
    const studentHasLearntSound = await this._studentHasLearntSound(sound)

    if (studentHasLearntSound) {
      const manifests = [[sound, 'sounds']]
      return { manifests, contentSpec }
    }

    // This student hasn't yet learnt this sound - abort the transition or redirect.
    if (transition.from) {
      void transition.abort()
    } else {
      void this.router.replaceWith('my-progress.sounds')
    }

    return undefined
  }

  /**
   * Resolves to true if the student has access to the sound in question
   */
  async _studentHasLearntSound(sound: string): Promise<boolean> {
    const { sounds } = await this.student.studentResult

    assert('sounds exist on studentResult', sounds)
    return Object.entries(sounds).some(([_peak, peakSounds]) =>
      peakSounds.includes(sound),
    )
  }
}
