import Service, { inject as service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import type { Log } from '@blakeelearning/log'
import type Store from '@ember-data/store'
import config from 'fast-phonics-client/config/environment'
import guestStudentPayload from 'fast-phonics-client/utils/guest-student'
import type StudentModel from 'fast-phonics-client/models/student'
import { assert } from '@ember/debug'
import type { FeatureService } from '@blakeelearning/features'
import { InMemoryProvider } from '@openfeature/web-sdk'

const {
  APP: { loginUrl },
} = config

type FlagConfiguration = NonNullable<
  ConstructorParameters<typeof InMemoryProvider>[number]
>

function createInMemoryProvider(
  flags: Record<
    string,
    {
      type: string
      value: unknown
    }
  > = {},
): InMemoryProvider {
  const flagConfiguration: FlagConfiguration = {}

  for (const [name, flag] of Object.entries(flags)) {
    switch (flag.type) {
      case 'boolean':
        flagConfiguration[name] = {
          defaultVariant: JSON.stringify(flag.value),
          disabled: false,
          variants: {
            true: true,
            false: false,
          },
        }
        break
    }
  }

  return new InMemoryProvider(flagConfiguration)
}

export default class SessionService extends Service {
  @tracked _student?: StudentModel

  @service declare log: Log

  @service declare store: Store

  @service declare features: FeatureService

  isGuestSession(): boolean {
    return config.APP.guest
  }

  async setStudent(): Promise<void> {
    let student

    if (this.isGuestSession()) {
      student = this.makeGuestStudent()
    } else {
      student = await this.store.queryRecord('student', {
        id: 'self',
        include: 'progress,reward-totals,purchases',
      })
    }

    assert('student is not null', student !== null)
    this.log.setPerson({ id: student.id })
    this._student = student

    const provider = createInMemoryProvider(student.featureFlags)
    await this.features.setup({ provider, logger: this.log })
  }

  signIn(location = window.location) {
    if (loginUrl) location.href = loginUrl
  }

  reloadStudent({
    include = 'progress,reward-totals,purchases',
  } = {}): Promise<StudentModel> {
    const { id } = this.student
    return this.store.findRecord('student', id, { reload: true, include })
  }

  makeGuestStudent(): StudentModel | null {
    this.store.pushPayload(guestStudentPayload)
    return this.store.peekRecord('student', guestStudentPayload.data.id)
  }

  get student(): StudentModel {
    if (!this._student) throw new Error('student is unset')
    return this._student
  }

  get accent(): string {
    const { defaultAccent } = config.APP
    const client = this.features.getClient()
    const isCanadaAware = client.getBooleanValue('canadaAwareFF', false)

    if (this.student.accent === 'ca' && !isCanadaAware) {
      return 'us'
    }

    return this.student.accent ?? defaultAccent
  }

  get countryCode(): string | null {
    return this.student.countryCode?.toUpperCase() ?? null
  }

  get schoolCountryCode(): string | null {
    return this.student.schoolCountryCode?.toUpperCase() ?? null
  }
}

declare module '@ember/service' {
  interface Registry {
    session: SessionService
  }
}
