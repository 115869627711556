import type { AsyncBelongsTo } from '@ember-data/model'
import Model, { attr, belongsTo } from '@ember-data/model'
import type StudentModel from 'fast-phonics-client/models/student'
import type { Attr } from 'fast-phonics-client/utils/models'

interface PeakQuizResults {
  best: number
  first: number
  certificate: Certificate | null
}

interface Certificate {
  colour: 'gold' | 'silver' | 'bronze'
  created: string
  url?: string // ### NEW ###
}

export default class StudentResultModel extends Model {
  @attr() averageBestPeakQuizResult: Attr<unknown>

  @attr() declare peakQuizResults: Record<string, PeakQuizResults>

  @attr() sounds: Attr<Record<string, string[]>>

  @attr() books: Attr<string[]>

  @attr() words: Attr<Record<string, string[]>>

  @belongsTo('student', { async: true, inverse: 'studentResult' })
  declare student: AsyncBelongsTo<StudentModel>
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'student-result': StudentResultModel
  }
}
