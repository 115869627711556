/**
 * This util is designed to be used in conjuntion with the hidden-print-container component.
 */
export default async function printImage(url: string): Promise<void> {
  const hiddenPrintContainerId = 'hidden-print-container'

  const printContainer = document.querySelector(`div#${hiddenPrintContainerId}`)
  if (!printContainer) throw new Error('Missing print container element')

  while (printContainer.firstChild) {
    printContainer.firstChild.remove()
  }

  const hiddenImage = document.createElement('img')
  hiddenImage.src = url

  const printPromise = new Promise<void>((resolve, reject) => {
    hiddenImage.onload = () => {
      /**
       * This (ugly but functional) timeout is the most reliable way to ensure that Safari prints a fully rendered certificate.
       * The following didn't work:
       * -------------------------------
       * await hiddenImage.decode()
       * resolve()
       * window.print()
       * -------------------------------
       * hiddenImage.decoding = 'sync' // Tried on it's own and incombination with the above
       * -------------------------------
       * requestAnimationFrame(() => {
       *  requestAnimationFrame(() => {
       *    resolve()
       *    window.print()
       *  })
       * })
       * -------------------------------
       */
      setTimeout(() => {
        resolve()
        const hiddenPrintImage = document.querySelector(`div#${hiddenPrintContainerId} > img`)
        if (hiddenPrintImage === hiddenImage) window.print()
      }, 500)
    }
    hiddenImage.onerror = () => {
      reject(new Error('Failed to load image'))
    }
  })

  printContainer.appendChild(hiddenImage)

  return printPromise
}
