import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type { ProgressData } from 'fast-phonics-client/models/progress'
import { assert } from '@ember/debug'
import { contentSpecsByActivityType } from '@blakeelearning/content-specs-fast-phonics'
import type ActivityService from 'fast-phonics-client/services/activity'
import type { Log } from '@blakeelearning/log'
import type SessionService from 'fast-phonics-client/services/session'
import type ProgressService from 'fast-phonics-client/services/progress'
import type RouterService from '@ember/routing/router-service'
import type RewardTotalModel from 'fast-phonics-client/models/reward-total'
import type Transition from '@ember/routing/-private/transition'
import type { ParamsFor } from 'fast-phonics-client/utils/route-model'
import type StoneRoute from 'fast-phonics-client/routes/stone'
import toJSON from 'fast-phonics-client/utils/to-json'

export type ParamsForStoneRoute = ParamsFor<StoneRoute>

type ActivityContentSpec =
  | (typeof contentSpecsByActivityType)['book']
  | (typeof contentSpecsByActivityType)['interactive']
  | (typeof contentSpecsByActivityType)['quiz']
  | (typeof contentSpecsByActivityType)['video']
interface ModelForRoute {
  manifests: string[][]
  variables: Record<string, unknown>
  peakId: number
  stoneId: string
  activityId: string
  activityType: keyof typeof contentSpecsByActivityType
  contentSpec: ActivityContentSpec
  completeVideoCalled?: boolean
  completeBookQuizCalled?: boolean
}

export default class StoneIndexRoute extends Route<ModelForRoute> {
  @service declare activity: ActivityService

  @service declare log: Log

  @service declare session: SessionService

  @service declare progress: ProgressService

  @service declare router: RouterService

  get lessonsProgressData(): ProgressData {
    return this.progress.lessonsProgressData
  }

  get studentFirstName(): string {
    assert('firstName is set', this.session.student.firstName)
    return this.session.student.firstName
  }

  get userRewards(): RewardTotalModel {
    return this.session.student.rewardTotals
  }

  override beforeModel(transition: Transition): void {
    if (this.progress.showPlacementTest) {
      void this.router.transitionTo('placement-test')
      return
    }

    const parent = transition.to?.parent
    assert('transition is not null', parent)
    const { params } = parent

    const { peak_id, stone_id: stoneId } = params
    const peakId = Number(peak_id)

    assert('stoneId is defined', stoneId !== undefined)
    const canPlayStone = this.progress.canPlayStone(peakId, stoneId)

    if (!canPlayStone) {
      // Send them to the peaks view.
      void this.router.replaceWith('peaks.peak', peakId)
    }
  }

  override model(): ModelForRoute {
    const { peak_id, stone_id: stoneId } = this.paramsFor(
      'stone',
    ) as ParamsForStoneRoute
    const peakId = Number(peak_id)

    const activityResult = this.activity.findActivityByStoneId(peakId, stoneId)
    if (!activityResult.ok) throw new Error(activityResult.errorMessage)

    const { id: activityId, activityType } = activityResult.activity
    const {
      studentFirstName,
      userRewards,
      lessonsProgressData: userProgress,
    } = this

    const manifests = [[stoneId, `activities/peaks/${peakId.toFixed()}`]]
    const variables = {
      studentFirstName,
      userRewards: toJSON(userRewards),
      userProgress,
    }

    assert(
      'activityType is set',
      activityType === 'book' ||
        activityType === 'video' ||
        activityType === 'quiz' ||
        activityType === 'interactive',
    )
    const contentSpec = contentSpecsByActivityType[activityType]

    return {
      manifests,
      variables,
      peakId,
      stoneId,
      activityId,
      activityType,
      contentSpec,
    }
  }
}
