import type { AsyncBelongsTo } from '@ember-data/model'
import Model, { attr, belongsTo } from '@ember-data/model'
import type StudentModel from 'fast-phonics-client/models/student'
import type { Attr } from 'fast-phonics-client/utils/models'

export default class ActivityResultModel extends Model {
  @attr('string') uuid: Attr<string>

  @attr('string') activityId: Attr<string>

  @attr('number') coinsEarned: Attr<number>

  @attr('number') soundGemsEarned: Attr<number>

  @attr('number') wordGemsEarned: Attr<number>

  @attr('number') correctCount: Attr<number>

  @attr('number') totalCount: Attr<number>

  @attr() soundsEarned: Attr<string[]>

  @attr() wordsEarned: Attr<string[]>

  @belongsTo('student', { async: true, inverse: 'activityResults' })
  declare student: AsyncBelongsTo<StudentModel>
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'activity-result': ActivityResultModel
  }
}
