import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { UnauthorizedError } from '@ember-data/adapter/error'
import config from 'fast-phonics-client/config/environment'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'
import type RouterService from '@ember/routing/router-service'
import type SessionService from 'fast-phonics-client/services/session'
import type ProgressService from 'fast-phonics-client/services/progress'

const {
  APP: { debugErrors },
} = config

export default class ApplicationErrorController extends Controller {
  debugErrors = debugErrors

  @service declare loadingUi: LoadingUiService

  @service declare router: RouterService

  @service declare session: SessionService

  @service declare progress: ProgressService

  get isRootRoute(): boolean {
    const routeName = this.router.currentRoute.name
    return ['peaks.index', 'peaks.peak.index', 'index'].includes(routeName)
  }

  /**
   * This is a best guess at whether the app is broken in some unrecoverable
   * way.  At present we assume that this is so if we have arrived in this
   * error sub-state without a student model, or if we have arrived here from
   * a route that doesn't have an obvious place that we can redirect to.
   */
  get isAppBroken(): boolean {
    try {
      return this.isRootRoute || !this.session.student.progress
    } catch {
      return true
    }
  }

  get placementTestRequired(): boolean {
    return this.progress.showPlacementTest
  }

  get isUnauthorizedError(): boolean {
    const error = this.model
    return error instanceof UnauthorizedError
  }

  get errorScreenComponent(): string {
    if (this.isUnauthorizedError) {
      return 'error-screen/login'
    }
    if (this.isAppBroken) {
      return 'error-screen/logout'
    }
    if (this.placementTestRequired) {
      return 'error-screen/logout'
    }
    return 'error-screen'
  }
}

declare module '@ember/controller' {
  interface Registry {
    'application-error': ApplicationErrorController
  }
}
