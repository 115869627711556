import type {
  AsyncBelongsTo,
  AsyncHasMany,
  SyncHasMany,
} from '@ember-data/model'
import Model, { attr, belongsTo, hasMany } from '@ember-data/model'
import type { Attr } from 'fast-phonics-client/utils/models'
import type RewardTotalModel from 'fast-phonics-client/models/reward-total'
import type StudentResultModel from 'fast-phonics-client/models/student-result'
import type ProgressModel from 'fast-phonics-client/models/progress'
import type PurchaseModel from 'fast-phonics-client/models/purchase'
import type ActivityResultModel from 'fast-phonics-client/models/activity-result'
import type BookQuizResultModel from 'fast-phonics-client/models/book-quiz-result'

interface Flag {
  type: string
  value: unknown
}

export default class StudentModel extends Model {
  @attr('string') locale: Attr<string>

  @attr('string') accent: Attr<string>

  @attr('string') firstName: Attr<string>

  @attr('string') lastName: Attr<string>

  @attr('string') selectedYeti: Attr<string>

  @attr('boolean') viewedIntroVideo: Attr<boolean>

  @attr('string') countryCode: Attr<string>

  @attr('string') schoolCountryCode: Attr<string>

  @attr featureFlags: Record<string, Flag> | undefined

  @hasMany('activity-result', { async: true, inverse: 'student' })
  declare activityResults: AsyncHasMany<ActivityResultModel>

  @hasMany('book-quiz-result', { async: true, inverse: 'student' })
  declare bookQuizResults: AsyncHasMany<BookQuizResultModel>

  @belongsTo('student-result', { async: true, inverse: 'student' })
  declare studentResult: AsyncBelongsTo<StudentResultModel>

  @hasMany('progress', { async: false, inverse: 'student' })
  declare progress: SyncHasMany<ProgressModel>

  @belongsTo('reward-total', { async: false, inverse: 'student' })
  declare rewardTotals: RewardTotalModel

  @hasMany('purchase', { async: false, inverse: 'student' })
  declare purchases: SyncHasMany<PurchaseModel>

  get abbreviatedName(): string {
    // Trim whitespace to prevent weird spacing
    const firstNameTrimmed = this.firstName?.trim() ?? ''
    const lastNameTrimmed = this.lastName?.trim() ?? ''

    // Initial of last name only for COPPA compliance
    const lastNameInitial = lastNameTrimmed.charAt(0)

    return lastNameTrimmed === ''
      ? `${firstNameTrimmed}.`
      : `${firstNameTrimmed} ${lastNameInitial}.`
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    student: StudentModel
  }
}
