export function getLastNavigationType() {
  if (window.performance === undefined) return undefined

  const supportsGetPerfEntries =
    typeof window.performance.getEntriesByType === 'function'
  let currentNavigation
  let navigationType

  if (supportsGetPerfEntries) {
    ;[currentNavigation] = window.performance
      .getEntriesByType('navigation')
      .slice(-1)
  }

  if (currentNavigation) {
    // If getEntriesByType exists, and returns a non-empty array, then the
    // browser is assumed to support Navigation Timing Level 2 API, which
    // returns a string type
    // https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/type
    navigationType = currentNavigation.type
  } else {
    // If getEntriesByType doesn't exist, or returns an empty array, then the
    // browser is assumed to support Navigation Timing Level 1 API, which
    // returns an integer type.
    // This is the case for Safari 9 and upwards, as of July 2020.
    // https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigation/type
    currentNavigation = window.performance.navigation.type

    navigationType = {
      0: 'navigate',
      1: 'reload',
      2: 'back_forward',
    }[currentNavigation]
  }

  return navigationType
}
