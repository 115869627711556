import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import { assert } from '@ember/debug'
import type { MyProgressContentSpec } from '@blakeelearning/content-specs-fast-phonics'
import { myProgressContentSpec as contentSpec } from '@blakeelearning/content-specs-fast-phonics'
import type ProgressService from 'fast-phonics-client/services/progress'
import type SessionService from 'fast-phonics-client/services/session'
import type RouterService from '@ember/routing/router-service'
import type StudentModel from 'fast-phonics-client/models/student'
import type RewardTotalModel from 'fast-phonics-client/models/reward-total'
import type Transition from '@ember/routing/-private/transition'
import toJSON from 'fast-phonics-client/utils/to-json'
import type PeakCertificateService from 'fast-phonics-client/services/peak-certificate'
import type StudentResultModel from 'fast-phonics-client/models/student-result'

interface ModelForRoute {
  manifests: string[][]
  variables: Record<string, unknown>
  contentSpec: MyProgressContentSpec
}

export default class MyProgressRoute extends Route<ModelForRoute> {
  @service declare progress: ProgressService

  @service declare session: SessionService

  @service declare router: RouterService

  @service declare peakCertificate: PeakCertificateService

  get student(): StudentModel {
    return this.session.student
  }

  get studentFirstName(): string {
    assert('student has firstName attr', this.student.firstName)
    return this.student.firstName
  }

  get userRewards(): RewardTotalModel {
    assert('student has first rewardTotals attr', this.student.rewardTotals)
    return this.student.rewardTotals
  }

  override beforeModel(): void {
    if (this.progress.showPlacementTest) {
      void this.router.transitionTo('placement-test')
    }
  }

  override async model(_params: unknown, transition: Transition) {
    const { studentFirstName } = this

    const studentResult = (await this.student
      .belongsTo('studentResult')
      .reload()) as StudentResultModel

    // adding a certificate url to each peakQuizResult that has a certificate.
    for (const [peakId, { certificate }] of Object.entries(
      studentResult.peakQuizResults,
    )) {
      // Not all peaks will have certificates
      if (certificate) {
        certificate.url = this.peakCertificate.generateCertificateUrl({
          peakId: parseInt(peakId, 10),
          colour: certificate.colour,
          name: studentFirstName,
          timestamp: Date.parse(certificate.created),
        })
      }
    }

    const manifests = [['index', 'activities/progress-page']]
    const variables = {
      studentFirstName,
      // Use toJSON so we don't pass ember objects to content interactive
      results: toJSON(studentResult),
      userRewards: toJSON(this.userRewards),
      requestedTab: this._initialTab(transition),
      peaksWithoutSounds: [13, 14, 18, 19, 20],
    }

    return { manifests, variables, contentSpec }
  }

  _initialTab(transition: Transition): string {
    switch (transition.to?.localName) {
      case 'sounds':
        return 'sounds'
      case 'words':
        return 'words'
      case 'books':
        return 'books'
      case 'certificates':
        return 'certificates'
      default:
        return 'progress'
    }
  }
}
