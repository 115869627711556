import Transform from '@ember-data/serializer/transform'

class IncorrectAnswersTransform extends Transform {
  /**
   * Converts value to a format that the client expects
   *
   * @method deserialize
   * @param serialized The serialized value
   * @return The deserialized value
   */
  override deserialize(serialized: unknown): string[][] {
    if (Array.isArray(serialized)) {
      return serialized.map((items) => {
        if (Array.isArray(items)) {
          return items.map((answer) => String(answer))
        }
        return []
      })
    }
    return []
  }

  /**
   * Converts value to the format expected by backend
   *
   * @method serialize
   * @param deserialized The deserialized value
   * @returns The serialized value
   */
  override serialize(deserialized: string[][]): string[][] {
    const hasTopLevelArray = Array.isArray(deserialized)

    if (hasTopLevelArray) {
      const hasSecondLevelArray = Array.isArray(deserialized[0])

      if (hasSecondLevelArray) {
        const serializeSecondLevel = deserialized.map((item) =>
          item.map((answer) => String(answer)),
        )
        return serializeSecondLevel
      }
      const serializeTopLevel = [deserialized.map((item) => String(item))]
      return serializeTopLevel
    }
    return []
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'incorrect-answers': IncorrectAnswersTransform
  }
}

export default IncorrectAnswersTransform
