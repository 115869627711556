import JSONAPIAdapter from '@ember-data/adapter/json-api'
import config from 'fast-phonics-client/config/environment'

const {
  APP: { apiEndpoint, apiNamespace },
} = config

/**
 * Adapter for connecting to the backend. Ajax mixin is used to leverage the error handling hooks.
 */
export default class ApplicationAdapter extends JSONAPIAdapter {
  override coalesceFindRequests = true

  // @ts-expect-error this can be undefined
  override get host() {
    return apiEndpoint
  }

  override get namespace() {
    return apiNamespace
  }

  override ajaxOptions(
    url: string,
    type: string,
    options: Record<string, unknown>,
  ) {
    return {
      ...super.ajaxOptions(url, type, options),
      credentials: 'include',
    }
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    application: ApplicationAdapter
  }
}
