import Route from '@ember/routing/route'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import { placementTestContentSpec as contentSpec } from '@blakeelearning/content-specs-fast-phonics'
import type DebugModeService from 'fast-phonics-client/services/debug-mode'
import type DeviceDetection from '@blakeelearning/device/device/detection/service'
import type ProgressService from 'fast-phonics-client/services/progress'
import type SessionService from 'fast-phonics-client/services/session'

interface ModelForRoute {
  contentSpec: unknown
  accentOverride?: string
  manifests: string[][]
  variables: Record<string, unknown>
}

// eslint-disable-next-line ember/no-test-import-export
export default class PlacementTestRoute extends Route<ModelForRoute> {
  @service declare debugMode: DebugModeService

  @service declare progress: ProgressService

  @service declare session: SessionService

  @service('device/detection') declare deviceDetection: DeviceDetection

  @service declare router: RouterService

  get studentAccent(): string {
    return this.session.accent
  }

  get studentName(): string {
    return this.session.student.abbreviatedName
  }

  override beforeModel(): void {
    if (!this.progress.showPlacementTest) {
      void this.router.transitionTo('/')
    }
  }

  override model() {
    const accentOverride = this.studentAccent

    const manifests = [['index', 'activities/new-placement-test']]

    const variables = {
      studentName: this.studentName,
    }
    return { manifests, variables, accentOverride, contentSpec }
  }

  @action
  override willTransition(): true {
    return true
  }
}
