import Service, { service } from '@ember/service'
import { contentError } from 'fast-phonics-client/utils/errors'
import { base64url } from 'rfc4648'
import printImage from 'fast-phonics-client/utils/print-image'
import config from 'fast-phonics-client/config/environment'
import type DeviceDetection from '@blakeelearning/device/device/detection/service'
import type { QuizData } from 'fast-phonics-client/controllers/stone/index'

const { certificateHost } = config.APP

interface PrintArgs {
  certificateUrl: string
}
export interface CertificateData {
  peakId: number
  name: string
  colour: 'gold' | 'silver' | 'bronze'
  timestamp: number
}

export default class PeakCertificateService extends Service {
  @service('device/detection') declare deviceDetection: DeviceDetection

  /**
   * Prints a certificate
   */
  async print(args: PrintArgs): Promise<void> {
    try {
      const certificateUrl = new URL(args.certificateUrl)
      if (this.deviceDetection.isNativeMobile) {
        // The prescence of this param will result in an SVG element with `width=100% height=auto` attributes. The mobile apps require this for print layout.
        certificateUrl.searchParams.set('fitpage', '')
        // Mobile apps will intercept these calls and take care of printing from there.
        window.open(certificateUrl.href, '_blank')
        return
      }

      await this._printImage(certificateUrl.href)
    } catch (error) {
      throw contentError('PrintFailed', error)
    }
  }

  /**
   * This replicates the logic in fast_phonics_api. Eventually the BE will supply us with a certificate URL and we can remove this method.
   * https://github.com/blake-education/fast_phonics_api/blob/dacb79513048fde0dbdd314d54227c68a32891fe/lib/fast_phonics_api/peak_quiz_result.ex#L48
   */
  calculateColour({
    correctAnswers,
    incorrectAnswers,
  }: QuizData): CertificateData['colour'] {
    const correctCount = correctAnswers.length
    const incorrectCount = incorrectAnswers?.length ?? 0
    const percentage = Math.round(
      (correctCount / (correctCount + incorrectCount)) * 100,
    )
    if (percentage === 100) return 'gold'
    if (percentage >= 90) return 'silver'
    if (percentage >= 80) return 'bronze'
    throw new Error(
      `Score percentage "${percentage.toFixed()}" out of certificate range: 80-100`,
    )
  }

  generateCertificateUrl({ peakId, colour, name, timestamp }: CertificateData) {
    const base64Url = base64url.stringify(
      new TextEncoder().encode(
        JSON.stringify([peakId, colour, name, timestamp]),
      ),
      { pad: false },
    )
    const url = new URL(`fp/legacy/${base64Url}.svg`, certificateHost)

    return url.href
  }

  _printImage(url: string) {
    return printImage(url)
  }
}
declare module '@ember/service' {
  interface Registry {
    'peak-certificate': PeakCertificateService
  }
}
