import type { AsyncBelongsTo } from '@ember-data/model'
import Model, { attr, belongsTo } from '@ember-data/model'
import type { Attr } from 'fast-phonics-client/utils/models'
import type StudentModel from 'fast-phonics-client/models/student'

/**
 * Courtesy Note:
 *
 * Reward Totals are passed as an `included` resource on the student model.
 * Implementation of this  can be viewed in the student model, and in the app/session service
 * where `reward-totals` is included as a request param.
 *
 * See JSON:API Spec for more info on the `include` request param.
 *
 */

export default class RewardTotalModel extends Model {
  @attr('number') coins: Attr<number>

  @attr('number') bookGems: Attr<number>

  @attr('number') soundGems: Attr<number>

  @attr('number') wordGems: Attr<number>

  @belongsTo('student', { async: true, inverse: 'rewardTotals' })
  declare student: AsyncBelongsTo<StudentModel>
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reward-total': RewardTotalModel
  }
}
