import Model, { attr } from '@ember-data/model'
import type { Attr } from 'fast-phonics-client/utils/models'

export default class YetiModel extends Model {
  @attr('number') price: Attr<number>
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    yeti: YetiModel
  }
}
