import Transform from '@ember-data/serializer/transform'
import { isNone } from '@ember/utils'

class CorrectAnswersTransform extends Transform {
  /**
   * Converts value to a format that the client expects
   *
   * @method deserialize
   * @param serialized The serialized value
   * @return The deserialized value
   */
  override deserialize(serialized: unknown): string[] {
    if (Array.isArray(serialized)) {
      return serialized.map((answer) => String(answer))
    }
    return []
  }

  /**
   * Converts value to the format expected by backend
   *
   * @method serialize
   * @param deserialized The deserialized value
   * @returns The serialized value
   */
  override serialize(deserialized: string[]): string[] {
    return isNone(deserialized)
      ? []
      : deserialized.map((answer) => String(answer))
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'correct-answers': CorrectAnswersTransform
  }
}

export default CorrectAnswersTransform
