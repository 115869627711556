import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type RouterService from '@ember/routing/router-service'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'
import type { MyProgressContentActions } from '@blakeelearning/content-specs-fast-phonics'
import type { FeatureService } from '@blakeelearning/features'
import type PeakCertificateService from 'fast-phonics-client/services/peak-certificate'

export default class MyProgressController extends Controller {
  @service declare errorHandler: ErrorHandlerService

  @service declare loadingUi: LoadingUiService

  @service declare peakCertificate: PeakCertificateService

  @service declare router: RouterService

  @service declare features: FeatureService

  get isDebugAllowed(): boolean {
    const client = this.features.getClient()
    return client.getBooleanValue('debugAllowedFF', false)
  }

  get contentActions(): MyProgressContentActions {
    return {
      readyForUserInput: () => {
        this.loadingUi.finish()
      },
      unhandledError: ({ error }: { error: unknown }) => {
        this.errorHandler.handleContentUnhandledError(error)
      },
      goBack: () => {
        this.goBack()
      },
      goToTab: (tabName: string) => {
        this.goToTab(tabName)
      },
      readBook: (bookId: string) => {
        this.readBook(bookId)
      },
      watchSoundVideo: (sound: string) => {
        this.watchSoundVideo(sound)
      },
      print: (args) => this.peakCertificate.print(args),
    }
  }

  @action goBack(): void {
    this.loadingUi.start(() => {
      window.history.back()
    })
  }

  /**
   * Replace the route with the one that matches the tab we are on, so long
   * as we recognise the tab.
   */
  @action goToTab(tabName: string): void {
    switch (tabName) {
      case 'certificates':
        void this.router.replaceWith('my-progress.certificates')
        break
      case 'sounds':
        void this.router.replaceWith('my-progress.sounds')
        break
      case 'words':
        void this.router.replaceWith('my-progress.words')
        break
      case 'books':
        void this.router.replaceWith('my-progress.books')
        break
      case 'progress':
        void this.router.replaceWith('my-progress.index')
        break
      default:
        throw new Error(`Unknown tab name: ${tabName}`)
    }
  }

  @action readBook(bookId: string): void {
    this.loadingUi.start(() => {
      void this.router.transitionTo('book', bookId)
    })
  }

  @action watchSoundVideo(sound: string): void {
    this.loadingUi.start(() => {
      void this.router.transitionTo('sound', sound)
    })
  }
}

declare module '@ember/controller' {
  interface Registry {
    'my-progress': MyProgressController
  }
}
