/**
 * Finished Program Functions
 */

import { assert } from '@ember/debug'

type CanPlayResult = { ok: true } | { ok: false; message: string }

interface ActivityModel {
  peak: number | null | undefined
  positionInPeak: number | null | undefined
}

interface ProgressData {
  peak: number
  positionInPeak: number
}

export function hasFinishedProgram(
  studentProgress: ProgressData,
  lastActivity: ActivityModel,
): boolean {
  const { peak: studentProgressPeak } = studentProgress
  const { peak: lastActivityPeak } = lastActivity

  assert('lastActivityPeak is a number', typeof lastActivityPeak === 'number')

  const isFinishedProgram = studentProgressPeak > lastActivityPeak

  return isFinishedProgram
}

export function canPlayPeakLesson(
  studentProgress: ProgressData,
  peak: number,
): CanPlayResult {
  const { peak: currentPeak } = studentProgress
  if (currentPeak >= peak) {
    return { ok: true }
  }
  return {
    ok: false,
    message: `The requested peak is greater than the users current peak`,
  }
}

export function canPlayStoneActivity(
  studentProgress: ProgressData,
  requestedPeak: number,
  requestedStone: number,
): CanPlayResult {
  const { peak: currentPeak, positionInPeak: currentStone } = studentProgress

  if (requestedPeak < currentPeak) {
    return { ok: true }
  }

  if (requestedPeak === currentPeak) {
    if (requestedStone <= currentStone) {
      return { ok: true }
    }
  }

  if (requestedPeak > currentPeak) {
    return {
      ok: false,
      message: `The requested peak is greater than the users current peak`,
    }
  }

  if (requestedStone > currentStone) {
    return {
      ok: false,
      message: `The requested position is greater than the users current position`,
    }
  }

  // Default to false if the other cases aren't satisfied
  return {
    ok: false,
    message:
      'All previous cases failed, check that the user has progress data and that the requested Peak or Stone is valid',
  }
}
