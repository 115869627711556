import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type DeviceService from '@blakeelearning/content-loader/content-loader/device/service'

export default class ApplicationController extends Controller {
  @service('content-loader/device') declare device: DeviceService

  get shouldRotateDevice(): boolean {
    return this.device.isMobile && this.device.isPortrait
  }
}

declare module '@ember/controller' {
  interface Registry {
    application: ApplicationController
  }
}
