import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { warn } from '@ember/debug'
import type RouterService from '@ember/routing/router-service'
import config from 'fast-phonics-client/config/environment'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'
import type SessionService from 'fast-phonics-client/services/session'
import type ProgressService from 'fast-phonics-client/services/progress'
import type { EndOfProgramContentActions } from '@blakeelearning/content-specs-fast-phonics'

export default class EndOfProgramController extends Controller {
  @service declare errorHandler: ErrorHandlerService

  @service declare loadingUi: LoadingUiService

  @service declare session: SessionService

  @service declare progress: ProgressService

  @service declare router: RouterService

  get lastAccessiblePeak(): number {
    return this.progress.lastAccessiblePeak
  }

  get contentActions(): EndOfProgramContentActions {
    return {
      readyForUserInput: () => {
        this.loadingUi.finish()
      },
      unhandledError: ({ error }: { error: unknown }) => {
        this.errorHandler.handleContentUnhandledError(error)
      },
      goToPeak: () => {
        this.goToPeak()
      },
      goToApp: (appName: string, location: Location = window.location) => {
        this.goToApp(appName, location)
      },
      print: () => {
        this.print()
      },
    }
  }

  @action goToPeak(): void {
    this.loadingUi.start(() => {
      void this.router.transitionTo('peaks.peak', this.lastAccessiblePeak)
    })
  }

  @action goToApp(appName: string, location = window.location): void {
    const url = this._internalGoToApp(appName, location)
    location.href = url.href
  }

  @action print(): void {
    warn(
      `This is an empty action, to work you will need to ensure the interactive in jester-activities is receiving print() and that the printable PDF work is finalised.`,
      { id: 'end-of-program.print-action-failed' },
    )
  }

  /**
   * Utilises apiEndpoint to perform redirection
   */
  _internalGoToApp(appName: string, location: Location): URL {
    const url = new URL(
      'go-to-app/' + appName,
      config.APP.apiEndpoint ?? location.origin,
    )
    return url
  }
}

declare module '@ember/controller' {
  interface Registry {
    'end-of-program': EndOfProgramController
  }
}
