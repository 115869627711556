import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type RouterService from '@ember/routing/router-service'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'
import type ProgressService from 'fast-phonics-client/services/progress'
import type DebugModeService from 'fast-phonics-client/services/debug-mode'
import type SessionService from 'fast-phonics-client/services/session'
import type { VideoContentActions } from '@blakeelearning/content-specs-fast-phonics'

export default class IntroVideoController extends Controller {
  @service declare errorHandler: ErrorHandlerService

  @service declare loadingUi: LoadingUiService

  @service declare progress: ProgressService

  @service declare debugMode: DebugModeService

  @service declare session: SessionService

  @service declare router: RouterService

  get activityComponent(): string {
    if (this.debugMode.enabled) {
      return 'debug-mode/intro-video'
    }
    return 'content-interactive'
  }

  get contentActions(): VideoContentActions {
    return {
      readyForUserInput: () => {
        this.loadingUi.finish()
      },
      unhandledError: ({ error }: { error: unknown }) => {
        this.errorHandler.handleContentUnhandledError(error)
      },
      completeVideo: () => this.completeIntro(),
      goBack: () => {
        this.goBack()
      },
    }
  }

  async saveCompleteIntroResult(): Promise<void> {
    const { student } = this.session
    student.viewedIntroVideo = true

    try {
      await student.save()
    } catch (error) {
      student.rollbackAttributes()
      throw error
    }
  }

  /**
   * Need to guard against if the student has finished the program
   * If we don't, it will take them to a non existent peak and cause an application error
   */
  @action async completeIntro(): Promise<void> {
    await this.saveCompleteIntroResult()

    if (this.progress.showPlacementTest) {
      this.loadingUi.start(() => {
        void this.router.transitionTo('placement-test')
      })
    } else if (this.progress.hasFinishedProgram) {
      this.loadingUi.start(() => {
        void this.router.transitionTo('end-of-program')
      })
    } else {
      this.loadingUi.start(() => {
        void this.router.transitionTo('peaks')
      })
    }
  }

  @action goBack(): void {
    const { hasViewedIntroVideo } = this.progress

    this.loadingUi.start(() => {
      if (hasViewedIntroVideo) {
        window.history.back()
      } else {
        void this.router.transitionTo('logout')
      }
    })
  }
}

declare module '@ember/controller' {
  interface Registry {
    'intro-video': IntroVideoController
  }
}
