import Route from '@ember/routing/route'
import config from 'fast-phonics-client/config/environment'

const {
  APP: { logoutUrl, loginWarning },
} = config

interface ModelForRoute {
  warning: string | null
}

export default class LogoutRoute extends Route<ModelForRoute> {
  /**
   * Implementation of the model hook.  The `redirectUrl` is used by
   * `RedirectRoute`.
   */
  override model() {
    return {
      warning: loginWarning,
    }
  }

  /**
   * Implementation of the route redirect hook.  This will be called by Ember
   * after the model has been resolved.
   */
  override redirect(): void {
    if (logoutUrl) {
      const form = document.createElement('form')
      form.setAttribute('method', 'post')
      form.setAttribute('action', logoutUrl)

      document.body.appendChild(form)

      form.submit()
    }
  }
}
