import type { AsyncBelongsTo } from '@ember-data/model'
import Model, { attr, belongsTo } from '@ember-data/model'
import type StudentModel from 'fast-phonics-client/models/student'
import type { Attr } from 'fast-phonics-client/utils/models'

export interface ProgressData {
  positionInPeak: number
  peak: number
  showPlacementTest: boolean
}
export default class ProgressModel extends Model {
  @attr('string') precinct: Attr<string>

  @attr() progressData: Attr<ProgressData>

  @belongsTo('student', { async: true, inverse: 'progress' })
  declare student: AsyncBelongsTo<StudentModel>
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    progress: ProgressModel
  }
}
