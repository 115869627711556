import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type { EndOfProgramContentSpec } from '@blakeelearning/content-specs-fast-phonics'
import { endOfProgramContentSpec as contentSpec } from '@blakeelearning/content-specs-fast-phonics'
import type ActivityService from 'fast-phonics-client/services/activity'
import type SessionService from 'fast-phonics-client/services/session'
import type RouterService from '@ember/routing/router-service'
import type ProgressService from 'fast-phonics-client/services/progress'

interface ModelForRoute {
  contentSpec: EndOfProgramContentSpec
  manifests: string[][]
  variables: Record<string, unknown>
}

export default class EndOfProgramRoute extends Route<ModelForRoute> {
  @service declare activity: ActivityService

  @service declare session: SessionService

  @service declare router: RouterService

  @service declare progress: ProgressService

  get studentName(): string {
    return this.session.student.abbreviatedName
  }

  override beforeModel(): void {
    if (!this.progress.hasFinishedProgram || this.progress.showPlacementTest) {
      void this.router.replaceWith('/')
    }
  }

  override model() {
    const { studentName } = this
    const finalPeakNbr = this.activity.lastPeak
    const manifests = [['index', 'activities/finished-fast-phonics']]
    const variables = {
      studentName,
      finalPeakNbr,
    }
    return { manifests, variables, contentSpec }
  }
}
