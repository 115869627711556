import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type { VideoContentActions } from '@blakeelearning/content-specs-fast-phonics'

export default class SoundController extends Controller {
  @service declare loadingUi: LoadingUiService

  @service declare errorHandler: ErrorHandlerService

  get contentActions(): VideoContentActions {
    return {
      readyForUserInput: () => {
        this.loadingUi.finish()
      },
      unhandledError: ({ error }: { error: unknown }) => {
        this.errorHandler.handleContentUnhandledError(error)
      },
      completeVideo: () => {
        this.goBack()
      },
      goBack: () => {
        this.goBack()
      },
    }
  }

  @action goBack(): void {
    this.loadingUi.start(() => {
      window.history.back()
    })
  }
}

declare module '@ember/controller' {
  interface Registry {
    sound: SoundController
  }
}
