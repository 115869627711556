import Service, { inject as service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { assert } from '@ember/debug'
import type Store from '@ember-data/store'
import type ActivityModel from 'fast-phonics-client/models/activity'

type PositionResult =
  | { ok: true; id: string }
  | { ok: false; errorMessage: string }
type StoneResult =
  | { ok: true; id: number }
  | { ok: false; errorMessage: string }
type ActivityResult =
  | { ok: true; activity: ActivityModel }
  | { ok: false; errorMessage: string }

export default class ActivityService extends Service {
  @service declare store: Store

  /**
   * Returns either the list of activities set during {@link #load} or an empty
   * array, if load wasn't called
   *
   * @type array
   */
  @tracked _activities: ActivityModel[] = []

  get activities(): ActivityModel[] {
    return this._activities
  }

  get lastActivity(): ActivityModel {
    const lastActivity = this.activities.at(-1)
    assert('lastActivity is undefined', typeof lastActivity !== 'undefined')
    return lastActivity
  }

  get lastPeak(): number {
    assert(
      'lastActivity.peak is of type number',
      typeof this.lastActivity.peak === 'number',
    )
    return this.lastActivity.peak
  }

  /**
   * Converts positionInPeak to stoneId so the content-interactive knows which activity to load
   */
  findStoneIdFor(peakId: number, positionInPeak: number): PositionResult {
    const activity = this.activities.find(
      (a) => a.peak === peakId && a.positionInPeak === positionInPeak,
    )

    if (activity) {
      assert(
        'activity has valid manifestKey',
        typeof activity.manifestKey === 'string',
      )
      return {
        ok: true,
        id: activity.manifestKey,
      }
    }

    return {
      ok: false,
      errorMessage: `Check that Peak ID (${peakId.toFixed()}) & Position in Peak (${positionInPeak.toFixed()}) are returning a valid Activity (${String(
        activity,
      )})`,
    }
  }

  /**
   * Finds positionInPeak based off the stoneId
   */
  findPositionInPeakFor(peakId: number, stoneId: string): StoneResult {
    const activity = this.activities.find(
      (a) => a.peak === peakId && a.manifestKey === stoneId,
    )

    if (activity) {
      assert(
        'activity has valid positionInPeak',
        typeof activity.positionInPeak === 'number',
      )
      return {
        ok: true,
        id: activity.positionInPeak,
      }
    }

    return {
      ok: false,
      errorMessage: `Check that Peak ID (${peakId.toFixed()}) & Stone ID (${stoneId}) are returning a valid Activity (${String(
        activity,
      )})`,
    }
  }

  /**
   * Finds the activity ID based off positionInPeak
   */
  findActivityIdByPosition(
    peakId: number,
    positionInPeak: number,
  ): PositionResult {
    const activity = this.activities.find(
      (a) => a.peak === peakId && a.positionInPeak === positionInPeak,
    )

    if (activity) {
      return {
        ok: true,
        id: activity.id,
      }
    }

    return {
      ok: false,
      errorMessage: `Check that Peak ID (${peakId.toFixed()}) & Position in Peak (${positionInPeak.toFixed()}) are returning a valid Activity (${String(
        activity,
      )})`,
    }
  }

  /**
   * Finds the activity based off Stone Id
   */
  findActivityByStoneId(peakId: number, stoneId: string): ActivityResult {
    const activity = this.activities.find(
      (a) => a.peak === peakId && a.manifestKey === stoneId,
    )

    if (activity) {
      return {
        ok: true,
        activity,
      }
    }

    return {
      ok: false,
      errorMessage: `Check that Peak ID (${peakId.toFixed()}) & Stone ID (${stoneId}) are returning a valid Activity (${String(
        activity,
      )})`,
    }
  }

  /**
   *  Retrieves all activity records, loaded in application route so we have access to data immediately
   */
  async load(): Promise<void> {
    const activities = await this.store.findAll('activity')
    this._activities = activities.slice()
  }
}

declare module '@ember/service' {
  interface Registry {
    activity: ActivityService
  }
}
