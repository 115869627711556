export default {
  data: {
    type: 'students',
    id: 0,
    attributes: {
      name: 'Guest Student',
      'remote-id': 1,
      'first-name': 'Guest Student',
      eggs: 30765,
      'canonical-student-id': 0,
      locale: 'au',
    },
  },
}
