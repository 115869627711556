import type { AsyncBelongsTo } from '@ember-data/model'
import Model, { attr, belongsTo } from '@ember-data/model'
import type StudentModel from 'fast-phonics-client/models/student'
import type CorrectAnswersTransform from 'fast-phonics-client/transforms/correct-answers'
import type IncorrectAnswersTransform from 'fast-phonics-client/transforms/incorrect-answers'
import type { Attr } from 'fast-phonics-client/utils/models'

export default class BookQuizResult extends Model {
  @attr('boolean') bookGemEarned: Attr<boolean>

  @attr('string') activityId: Attr<string>

  @attr('string') bookName: Attr<string>

  @attr('number') coinsEarned: Attr<number>

  @attr('number') correctCount: Attr<number>

  @attr('number') incorrectCount: Attr<number>

  @attr('boolean') replayWithAudio: Attr<boolean>

  @attr('number') peak: Attr<number>

  @attr('boolean') passed: Attr<boolean>

  @attr('incorrect-answers')
  incorrectAnswers: Attr<ReturnType<IncorrectAnswersTransform['deserialize']>>

  @attr('correct-answers')
  correctAnswers: Attr<ReturnType<CorrectAnswersTransform['deserialize']>>

  @belongsTo('student', { async: true, inverse: 'bookQuizResults' })
  declare student: AsyncBelongsTo<StudentModel>
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'book-quiz-result': BookQuizResult
  }
}
