import { HTTPError } from 'ky'
import isNetworkError from 'is-network-error'
import { AbortError, UnauthorizedError } from '@ember-data/adapter/error'
import { ScriptBlockedError } from '@blakeelearning/content-loader'

/**
 * This describes any object that has a name and a message.  All standard
 * JavaScript errors (Error, TypeError, etc.) implement this interface.
 */
export interface ErrorLike {
  name: string
  message: string
}

type ErrorCheck = (error: unknown) => boolean

class ContentError extends Error {
  constructor(name: string, error: unknown) {
    let message: string
    let cause: ErrorLike | undefined

    if (isErrorLike(error)) {
      message = error.message
      cause = error
    } else if (typeof error === 'string') {
      message = error
    } else {
      message = `Unrecognised error: ${String(error)}`
    }

    super(message, { cause })
    this.name = name
  }
}

/**
 * There are two near-identical sets of network error classes in Ember - one
 * comes from fetch requests, and the other comes from ember-data.  The following
 * functions can be used to check for either.
 */

export const isUnauthorizedError: ErrorCheck = (error) =>
  (error instanceof HTTPError && error.response.status === 401) ||
  error instanceof UnauthorizedError

export const isAbortError: ErrorCheck = (error) =>
  isNetworkError(error) || error instanceof AbortError

export const isBadRequestError: ErrorCheck = (error) =>
  error instanceof HTTPError && error.response.status === 400

export const isServerError: ErrorCheck = (error) =>
  error instanceof HTTPError && error.response.status >= 500

function errorHasName(error: unknown): error is { name: unknown } {
  return error !== null && typeof error === 'object' && 'name' in error
}

export const isKyTimeoutError: ErrorCheck = (error) =>
  errorHasName(error) && error.name === 'TimeoutError'

/**
 * `ScriptBlockedError` indicates that content-loader failed to load a
 * JavaScript file into the DOM.  This is similar to an abort error, in that it
 * usually indicates a network failure.
 */
export const isScriptBlockedError: ErrorCheck = (error) =>
  error instanceof ScriptBlockedError ||
  (errorHasName(error) && error.name === 'ScriptBlockedError')

/**
 * This determines if a given error message/error object pair are actionable.
 * The definition of actionable is something that if we see it reported in
 * Rollbar, we can take some action to either resolve or ameliorate the
 * issue.
 *
 * At present, the errors we consider un-actionable are:
 *
 * 1. AbortErrors (either from ED or Ember Ajax)
 * 2. ScriptBlockedErrors from content-loader
 *
 * Anything else might be actionable.
 */
export function errorIsActionable(error: Error): boolean {
  if (error instanceof ContentError && isErrorLike(error.cause)) {
    return errorIsActionable(error.cause)
  }
  return !isAbortError(error) && !isScriptBlockedError(error)
}

/**
 * Wraps errors with the given error name, provided that they are not
 * considered a priori unrecoverable in the current context.  If they are, they
 * are returned unchanged.
 *
 * The "name" argument is intended to by used by the externalController wrapper
 * to determine if an error is recoverable.  As such, the errors created by
 * this function can be thrown from controller code interfacing with Content
 * when something goes awry.  The original error is wrapped using Error.cause so
 * that its stack trace (if any) is not lost.
 *
 * The second argument to this function can be an error object, an "error-like"
 * (object with name and message), a string, or any other type that can be
 * converted to a string.
 *
 * It is also possible to pass an error check function as the third argument.
 * If provided, this function will be used to determine whether to wrap the
 * error or not.  This is done because there are some errors that we never want
 * to give our content code the opportunity to recover from.  By default only
 * UnauthorizedErrors are treated as definitely unrecoverable.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause}
 * @example
 * try {
 *   doSomething()
 * } catch (error) {
 *   throw contentError('MyErrorName', error)
 * }
 *
 * @example
 * throw contentError('MyErrorName', 'my message')
 */
export function contentError(
  name: string,
  error: unknown,
  unrecoverableErrorCheck: ErrorCheck = isUnauthorizedError,
): unknown {
  if (unrecoverableErrorCheck(error)) {
    return error
  }
  return new ContentError(name, error)
}

export function isErrorLike(error: unknown): error is ErrorLike {
  return (
    typeof error === 'object' &&
    error !== null &&
    'name' in error &&
    'message' in error &&
    typeof error.name === 'string' &&
    typeof error.message === 'string'
  )
}
